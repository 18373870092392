import React, { Component } from 'react';

import location from './images/location-white.svg';
import transport from './images/transport-white.svg';
import hotel from './images/hotel-white.svg';

const data = [
	{
		icon: location,
		title: 'Location',
		text: 'CIO Education Congress 2018 will take place in the luxurious Bowood Hotel, Spa and Golf Resort, found in Calne, Wiltshire. Large, free car park for all meeting guests.',
		className: 'bg-color-grey'
	},
	{
		icon: transport,
		title: 'Transport',
		text: '15 minutes from M4 Junction 17. Chippenham Railway Station is approximately 14 minutes away. Transport can be booked at JJ’s Taxis, Chippenham, 01249 446436.',
		className: 'bg-color-dark-grey'
	},
	{
		icon: hotel,
		title: 'Hotel',
		text: 'Angel Hotel: 01249 652 615<br />Lucknam Park Hotel: 01225 742777<br />Premier Travel Inn: 08701 977 061<br />Beechfield House Hotel: 01225 703700',
		className: 'bg-color-charcoal'
	},
]

class VenueInfo extends Component {
	render() {
		return (
			<div>
				<div className='row' style={{paddingTop: 30}}>
					<div style={{textAlign: 'center', paddingBottom: 20}}>
						<p className='color-dark-grey' style={{fontSize: '1.5em', margin: 0}}>
							<strong>VENUE INFORMATION</strong>
						</p>
						<p className='color-grey' style={{margin: 0}}>Set in the picturesque grounds of Bowood Luxury Hotel and Spa, Calne, Wiltshire</p>
					</div>
				</div>
				<div className='row' style={styles.parent}>
					{data.map((elm) => {
						return <Box data={elm} key={elm.title} />
					})}
				</div>
			</div>
		);
	}
}

export default VenueInfo;

class Box extends Component {
	render () {
		return (
			<div className='col-xs-12 col-sm-4' style={styles.child}>
				<div className={this.props.data.className} style={styles.innerChild}>
					<img src={this.props.data.icon} style={{width: 50}} alt={this.props.data.title} />
					<br />
					<p>
						<strong style={{fontSize: '1.5em'}}>{this.props.data.title}</strong>
					</p>
					<p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: this.props.data.text}}></p>
					<br />
				</div>
			</div>
		)
	}
}

const styles = {
	parent: {
		overflow: 'hidden',
		marginBottom: 30
	},
	child: {
		marginBottom: '-99999px',
		paddingBottom: '99999px'
	},
	innerChild: {
		padding: 30,
		margin: 'auto',
		color: '#fff',
		marginBottom: '-99999px',
		paddingBottom: '99999px'
	}
}
