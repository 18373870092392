import React, { Component } from 'react';

import attendeesIcon from './images/attendees-white.svg';
import richardBrooks from './images/attendees/Richard-Brooks.jpg';
import richardMcKenna from './images/attendees/Richard-McKenna.jpg';
import matthewStreet from './images/attendees/Matthew-Street.jpg';
import andrewMale from './images/attendees/Andrew-Male.jpg';
import pekkaKahkipuro from './images/attendees/Pekka-Kahkipuro.jpg';
import nickLeake from './images/attendees/Nick-Leake.jpg';
import rebeccaFerriday from './images/attendees/Rebecca-Ferriday.jpg';
import jonFaulkner from './images/attendees/Jon-Faulkner.jpg';

class AttendeesImages extends Component {
	render() {
		return (
			<div className='row bg-color-charcoal' style={{padding: '30px 0px'}}>
				<div className='col-xs-12' style={{textAlign: 'center', marginBottom: 15}}>
					<p style={{fontSize: '1.5em', margin: 0, color: '#fff'}}>
						<strong>ATTENDEES</strong>
					</p>
					<img src={attendeesIcon} style={{width: 50}} alt='attendees icon' />
				</div>
				{attendees.map((elm, index) => {
					return <Box key={index} data={elm} />
				})}
			</div>
		);
	}
}

export default AttendeesImages;

function Box(props) {
	return (
		<div className='col-xs-6 col-sm-3 attendeesImages'>
			<div className='attendeesBox bg-color-yellow'>
				<img className='filterGray' src={props.data.image} style={{width: '100%'}} alt='' />
				<p style={{margin: 5}}>
					<strong>{props.data.name}</strong>
					<br />
					<span style={{fontSize: '0.7em'}}>{props.data.position}</span>
					<br />
					<span style={{fontSize: '0.7em', fontWeight: 'bold'}}>{props.data.company}</span>
				</p>
			</div>
		</div>
	)
}

const attendees = [
	{
		name: 'Pekka Kahkipuro',
		position: 'CIO',
		company: 'Brunel University London',
		image: pekkaKahkipuro
	},
	{
		name: 'Nick Leake',
		position: 'CIO',
		company: 'Kings College London',
		image: nickLeake
	},
	{
		name: 'Jon Faulkner',
		position: 'CIO',
		company: 'LSHTM',
		image: jonFaulkner
	},
	{
		name: 'Andrew Male',
		position: 'Assistant Director Information Services',
		company: 'University of York',
		image: andrewMale
	},
	{
		name: 'Richard McKenna',
		position: 'Assistant Head Learning and Research Technology',
		company: 'Manchester Metropolitan University',
		image: richardMcKenna
	},
	{
		name: 'Richard Brooks',
		position: 'Director of Human Resources',
		company: 'University of Bath',
		image: richardBrooks
	},
	{
		name: 'Rebecca Ferriday',
		position: 'Learning Technology Manager',
		company: 'University of Cardiff',
		image: rebeccaFerriday
	},
	{
		name: 'Matthew Street',
		position: 'Learning Technology Officer',
		company: 'Keele University',
		image: matthewStreet
	}
]
