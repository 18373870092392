import React, { Component } from 'react';

import banner from './images/banner.jpg';
import logoWhite from './images/logo-white.svg';

class Banner extends Component {
	render() {
		return (
			<div className='row banner-container font-montserrat' style={styles.container}>
				<p className='message1'>
					The Next Generation
					<br />
					In Learning Technology
				</p>
				<p className='message2'>
					23<sup>rd</sup> April 2018
					<br />
					Bowood Luxury Hotel & SPA, Calne
				</p>
				<img src={logoWhite} className='logo-overlay' alt='Logo white' />
			</div>
		);
	}
}

export default Banner;

const styles = {
	container: {
		position: 'relative',
		backgroundPosition: '90% bottom',
		backgroundImage: 'url(' + banner + ')'
	}
}
