import React, { Component } from 'react';
import Banner from './Banner';

import venue1 from './images/venue-1.jpg';
import venue2 from './images/venue-2.jpg';
import venue3 from './images/venue-3.jpg';

class Venue extends Component {
	render() {
		return (
			<div>
				<Banner />
				<div className='row' style={{padding: '30px 0px'}}>
					<div className='col-xs-12 col-sm-6' style={{textAlign: 'justify'}}>
						<p>
							<strong>Bowood Luxury Hotel and Spa</strong>
						</p>
						<p>Set in spectacular countryside, Bowood is an exceptional venue for meetings and corporate events in Wiltshire.</p> 
						<p>Offering extensive meeting facilities, a 43 bedroom hotel and a private lodge, all set in acres of immaculate grounds, the estate offers a truly unique event experience for your delegates.</p>
						<p>Bowood is also easy to get to – either by train to Chippenham station (1 hour 15 minutes from London Paddington) or via road from the M4, A4, M3 and A303 – putting it within easy driving distance of large cities and towns like London, Bath, Bristol, Cardiff and Swindon.</p>
						<br />
						<ul>
							<li>Truly secluded, distraction free location.</li>
							<li>Stunning entrance and beautiful surroundings – quality reflection of your company brand.</li>
							<li>Range of quality conference suites and meeting rooms holding from 4 to 240.</li>
							<li>All meeting rooms high spec with free WiFi and natural daylight.</li>
							<li>High quality and hugely flexible in-house catering prepared by our team of chefs.</li>
							<li>Boutique hotel, perfect for overnight meetings.</li>
							<li>Championship golf course and relaxing spa if you want to combine business with pleasure.</li>
							<li>Large free car park for all meeting guests</li>
						</ul>
						<br />
						<p>
							<strong>Contact Details</strong>
						</p>
						<p>Bowood, Calne, Wiltshire.<br />Bowood House: SN11 0LZ<br />Bowood Hotel: SN11 9PQ</p>
						<p>
							<strong>Telephone</strong>
						</p>
						<p>Bowood House: 01249 812102<br />Bowood Hotel: 01249 822228</p>
						<p>
							<strong>Email</strong>
						</p>
						<p>Bowood House: reception@bowood.org<br />Bowood Hotel: resort@bowood.org</p>
					</div>
					<div className='col-xs-12 col-sm-6'>
						<img src={venue1} alt='' className='venue-img' />
						<img src={venue2} alt='' className='venue-img' />
						<img src={venue3} alt='' className='venue-img' />
					</div>
				</div>
			</div>
		);
	}
}

export default Venue;
