import React, { Component } from 'react';
import Banner from './Banner';

import logoCompedia from './images/previous-events/2017/compedia-logo.png';
import logoSaiGlobal from './images/previous-events/2017/sai-global-logo.svg';
import logoIntelex from './images/previous-events/2017/intelex-logo.svg';
import logoHseRecruitment from './images/previous-events/2017/hse-recruitment-logo.png';
import logoDorsaVi from './images/previous-events/2017/dorsa-vi-logo.png';
import logoMrsTraining from './images/previous-events/2017/mrs-training-logo.svg';
import logoHbOnline from './images/previous-events/2017/hb-online-logo.png';
import logoDrager from './images/previous-events/2017/drager-logo.svg';
import logoEnablon from './images/previous-events/2017/enablon-logo.png';
import logoGensuite from './images/previous-events/2017/gensuite-logo.png';
import logoThamesWater from './images/previous-events/2017/thames-water-logo.svg';
import logoAbb from './images/previous-events/2017/abb-logo.svg';
import logoHilton from './images/previous-events/2017/hilton-logo.svg';
import logoSiemens from './images/previous-events/2017/siemens-logo.svg';
import logoVirginTrains from './images/previous-events/2017/virgin-trains-logo.svg';
import logoNetworkRail from './images/previous-events/2017/network-rail-logo.svg';
import logoIntegral from './images/previous-events/2017/integral-logo.png';
import logoJcb from './images/previous-events/2017/jcb-logo.svg';
import logoRollsRoyce from './images/previous-events/2017/rolls-royce-logo.svg';
import logoKongsberg from './images/previous-events/2017/kongsberg-logo.png';
import logoImperialBrands from './images/previous-events/2017/imperial-brands-logo.png';
import logoHeathrow from './images/previous-events/2017/heathrow-logo.svg';
import logoTheAa from './images/previous-events/2017/the-aa-logo.svg';
import logoCentrica from './images/previous-events/2017/centrica-logo.png';
import logoAlcatelLucent from './images/previous-events/2017/alcatel-lucent-logo.svg';
import logoBritishGas from './images/previous-events/2017/british-gas-logo.png';
import logoCbi from './images/previous-events/2017/cbi-logo.svg';
import logoDsSmith from './images/previous-events/2017/ds-smith-logo.png';
import logoInterserve from './images/previous-events/2017/interserve-logo.svg';
import logoMace from './images/previous-events/2017/mace-logo.svg';
import logoMorrisonUtilityServices from './images/previous-events/2017/morrison-utility-services-logo.svg';
import logoTataSteel from './images/previous-events/2017/tata-steel-logo.svg';
import logoBenugo from './images/previous-events/2017/benugo-logo.svg';
import logoWincanton from './images/previous-events/2017/wincanton-logo.svg';
import logoWillmottDixon from './images/previous-events/2017/willmott-dixon-logo.svg';

class PreviousEvents extends Component {
	render() {
		return (
			<div>
				<Banner />
				<div className='previous-events' style={styles.wrapper}>
					<div className='row'>
						<div style={{textAlign: 'center', paddingBottom: 30}}>
							<p className='color-dark-grey' style={{fontSize: '1.5em', margin: 0}}>
								<strong>HSE UK Congress 2017</strong>
							</p>
							<p className='color-grey' style={{margin: 0}}>Tuesday 26th September 2017<br />Bowood Luxury Hotel and Spa, Calne, Wiltshire</p>
						</div>
					</div>
					<div className='row' style={styles.row}>
						<p style={styles.rowTitle}>Workshop Sponsors</p>
						<div className='col-xs-12 col-sm-4' style={styles.col}>
							<img src={logoCompedia} style={styles.img} alt='' />
						</div>
						<div className='col-xs-12 col-sm-4' style={styles.col}>
							<img src={logoSaiGlobal} style={styles.img} alt='' />
						</div>
						<div className='col-xs-12 col-sm-4' style={styles.col}>
							<img src={logoIntelex} style={styles.img} alt='' />
						</div>
					</div>
					<br />
					<div className='row' style={styles.row}>
						<p style={styles.rowTitle}>Roundtable Sponsors</p>
						<div className='col-xs-12 col-sm-4' style={styles.col}>
							<img src={logoHseRecruitment} style={{...styles.img, ...{width: 'auto'}}} alt='' />
						</div>
						<div className='col-xs-12 col-sm-4' style={styles.col}>
							<img src={logoDorsaVi} style={styles.img} alt='' />
						</div>
						<div className='col-xs-12 col-sm-4' style={styles.col}>
							<img src={logoMrsTraining} style={styles.img} alt='' />
						</div>
					</div>
					<br />
					<div className='row' style={styles.row}>
						<p style={styles.rowTitle}>Exhibition Sponsors</p>
						<div className='col-xs-12 col-sm-6' style={styles.col}>
							<img src={logoHbOnline} style={{...styles.img, ...{width: 'auto'}}} alt='' />
						</div>
						<div className='col-xs-12 col-sm-6' style={styles.col}>
							<img src={logoDrager} style={styles.img} alt='' />
						</div>
					</div>
					<br />
					<div className='row' style={styles.row}>
						<p style={styles.rowTitle}>Sponsor Attendees</p>
						<div className='col-xs-12 col-sm-6' style={styles.col}>
							<img src={logoEnablon} style={styles.img} alt='' />
						</div>
						<div className='col-xs-12 col-sm-6' style={styles.col}>
							<img src={logoGensuite} style={styles.img} alt='' />
						</div>
					</div>
					<br />
					<br />
					<br />
					<div className='row' style={styles.row}>
						<p style={styles.rowTitle}>Executive Attendees From</p>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoThamesWater} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoAbb} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoHilton} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoSiemens} style={styles.img} alt='' />
						</div>
					</div>
					<div className='row' style={styles.row}>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoVirginTrains} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoNetworkRail} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoIntegral} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoJcb} style={styles.img} alt='' />
						</div>
					</div>
					<div className='row' style={styles.row}>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoRollsRoyce} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoKongsberg} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoImperialBrands} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoHeathrow} style={styles.img} alt='' />
						</div>
					</div>
					<div className='row' style={styles.row}>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoTheAa} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoCentrica} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoAlcatelLucent} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoBritishGas} style={styles.img} alt='' />
						</div>
					</div>
					<div className='row' style={styles.row}>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoCbi} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoDsSmith} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoInterserve} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoMace} style={styles.img} alt='' />
						</div>
					</div>
					<div className='row' style={styles.row}>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoMorrisonUtilityServices} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoTataSteel} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoWillmottDixon} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoWincanton} style={styles.img} alt='' />
						</div>
					</div>
					<div className='row' style={styles.row}>
						<div className='col-xs-6 col-sm-3' style={styles.col}>
							<img src={logoBenugo} style={styles.img} alt='' />
						</div>
						<div className='col-xs-6 col-sm-3' style={styles.col}></div>
						<div className='col-xs-6 col-sm-3' style={styles.col}></div>
						<div className='col-xs-6 col-sm-3' style={styles.col}></div>
					</div>
				</div>
			</div>
		);
	}
}

export default PreviousEvents;

const styles = {
	wrapper: {
		padding: '30px 0px'
	},
	row: {
		marginBottom: 20,
		textAlign: 'center'
	},
	rowTitle: {
		fontWeight: 'bold'
	},
	col: {
		height: 100
	},
	img: {
		position: 'absolute',
		padding: 15,
		width: '100%',
		maxWidth: 250,
		maxHeight: 100,
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	}
}
