import React, { Component } from 'react';

class Footer extends Component {
	render() {
		let text = 'CIO Education Congress is a product of Paul Clark Sales Consulting Limited, a registered company in England and Wales, company number 10680877. Registered Office: Reeds Yard, Bradford Road, Melksham, Wiltshire. SN12 8LQ';

		return (
			<div className='row' style={styles.wrapper}>
				<span style={styles.text}>{text}</span>
			</div>
		);
	}
}

export default Footer;

const styles = {
	wrapper: {
		padding: '5px 0px',
		borderTop: '1px solid #666666',
		textAlign: 'center',
		marginTop: 10
	},
	text: {
		fontSize: '0.65em'
	}
}
