import React, { Component } from 'react';
import Banner from './Banner';
import ShortDescription from './ShortDescription';
import ShortInfo from './ShortInfo';
import Schedule from './Schedule';
import AttendeesImages from './AttendeesImages';
import VenueInfo from './VenueInfo';

class Home extends Component {
	render() {
		return (
			<div>
				<Banner />
				<ShortDescription />
				<ShortInfo />
				<Schedule />
				<AttendeesImages />
				<VenueInfo />
			</div>
		);
	}
}

export default Home;
