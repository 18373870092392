import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import calendar from './images/calendar.svg';

import 'react-tabs/style/react-tabs.css';

class Schedule extends Component {
	render () {
		return (
			<div className='row' style={{padding: '30px 0px'}}>
				<div style={{textAlign: 'center', paddingBottom: 20}}>
					<img src={calendar} style={{width: 50}} alt="calendar" />
					<p className='color-dark-grey' style={{fontSize: '1.5em', margin: 0}}>
						<strong>SCHEDULE</strong>
					</p>
					<p className="color-grey" style={{margin: 0}}>MONDAY 23<sup>RD</sup> APRIL 2018</p>
				</div>
				<Day morning={data.day1.morning} afternoon={data.day1.afternoon} evening={data.day1.evening} />
			</div>
		);
	}
}

export default Schedule;

function Day(props) {
	return (
		<Tabs className='daytab'>
			<TabList style={{textAlign: 'center'}}>
				{props.morning.length ? <Tab>Morning</Tab> : ''}
				{props.afternoon ? <Tab>Afternoon</Tab> : ''}
				{props.evening.length ? <Tab>Evening</Tab> : ''}
			</TabList>
			<br />
			{props.morning.length ? (
				<TabPanel className='col-xs-12 col-sm-offset-1 col-sm-10 color-grey'>
					{props.morning.map(elm => {
						return <Row data={elm} key={elm.hours} />
					})}
				</TabPanel>
			) : ''}
			{props.afternoon.length ? (
				<TabPanel className='col-xs-12 col-sm-offset-1 col-sm-10 color-grey'>
					{props.afternoon.map(elm => {
						return <Row data={elm} key={elm.hours} />
					})}
				</TabPanel>
			) : ''}
			{props.evening.length ? (
				<TabPanel className='col-xs-12 col-sm-offset-1 col-sm-10 color-grey'>
					{props.evening.map(elm => {
						return <Row data={elm} key={elm.hours} />
					})}
				</TabPanel>
			): ''}
		</Tabs>
	);
}

class Row extends Component {
	render() {
		return (
			<div style={{borderTop: '1px solid #ddd', padding: '10px 0px 5px 0px', overflow: 'auto'}}>
				<div className='col-xs-12 col-sm-4'>{this.props.data.hours}</div>
				<div className='col-xs-12 col-sm-8'>
					<strong className='color-dark-grey' dangerouslySetInnerHTML={{__html: this.props.data.title}}></strong>
					<br />
					<span dangerouslySetInnerHTML={{__html: this.props.data.desc}}></span>
				</div>
			</div>
		)
	}
}

const data = {
	day1: {
		morning: [
			{
				hours: '07:00 - 08:30',
				title: 'Registration and Welcome',
				desc: ''
			},
			{
				hours: '08:30 - 09:30',
				title: 'Working Breakfast Panel',
				desc: ''
			},
			{
				hours: '09:30 - 10:30',
				title: 'Roundtable Session',
				desc: '<p><strong>Using cloud to learn, develop and profit.</strong></p>' +
					'<p>When funding and resources are limited, getting ahead of your peers can be difficult, particularly when legacy and shadow IT make it seem like you’re falling behind. During this roundtable discussion, Rackspace’s Leon Blakely, will help you recognise the areas of your day-to-day operations that you can develop using cloud technology. Throughout the session, we’ll be sharing examples of how we’ve enabled educational institutions to deliver innovative learning and research environments. Calling on our vast experience in the private sector, we’ll suggest cloud solutions that can help you invigorate your student learning experience.</p>' +
						'<p><strong>3 key take-ways:</strong></p>' +
						'<p>Rackspace expert will explain how to:</p>' +
						'<ul><li>Implement the right cloud platforms to help you develop innovative learning platforms</li><li>Handle your legacy and shadow IT challenges</li></ul>' +
						'Hosted by Leon Blakely, Senior Business Development Manager, Rackspace'
			},
			{
				hours: '10:30 - 10:50',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '10:50 - 11:30',
				title: 'Workshop 1',
				desc: '<p><strong>An Agile mindset: The future of L-Tech?</strong></p>' +
					'<p>Pressures on HE sector include diminishing student numbers, increasing costs, increasing competition and increased student expectation. L-tech is often looked to as a solution. However there can be a lack of academic and/or student buy-in because of the way change is implemented, glitches are discussed and who is consulted. Agile moves from the PRINCE2 model of hierarchical certainty to one of synergy, involving multiple stakeholders who hold equal responsibility. How and does this work for L-tech? Can the scrum mentality increase academic and student buy-in? Is it too devolved to actually aid the implementation of new technologies? Does it allow the tail to wag the dog? Who in fact is the expert in these scenarios?</p>' +
					'<p>Hosted by: Portia Ungley, Director of Learning and Teaching, Kingston University</p>'
			},
			{
				hours: '11:30 - 11:50',
				title: 'Coffee & Networking',
				desc: ''
			}
		],
		afternoon: [
			{
				hours: '11:50 - 12:30',
				title: 'Workshop 2',
				desc: '<p><strong>Flowmon Network performance monitoring and diagnostics and Behavior analytics</strong></p>' +
					'<p>A fast, reliable and well secured network is of crucial importance to any organization. Flowmon is the answer to this challenge using leading edge IP flow monitoring technology (NetFlow, IPFIX) to give you the best solution for network visibility. Go Flowmon and get everything you need to enhance the network & application performance and stop advanced cyber threats.</p>' +
					'<p>Network Behavior Analysis (NBA) is at the forefront of the technology-driven battle against modern cyber threats that bypass traditional perimeter and endpoint security. Whenever new security or operational issues arise, NBA is the light providing IT professionals with detailed network visibility and powerful behavior analytics to take decisive actions and manage the network with confidence.</p>' +
					'<p>Also web applications have problems that affect the satisfaction of your employees and customers. Identify and troubleshoot them before they impact your business with an agent-less solution to proactively drive user experience and application value.</p>' +
					'<p>Come and see how using Flowmon solution you can bring IT department, Security department and Application department closer together using one tool.</p>' +
					'<p><strong>3 key take-ways</strong></p>' +
					'<ul><li>Real-time network traffic visibility to have your network under control and quick troubleshooting.</li><li>Detection and diagnostics of operational and configuration issues.</li><li>Proactive detection of threats, botnets, DDoS and other risks undetectable by firewalls, IDS and antivirus.</li></ul>' +
					'<p>Hosted by:  Filip Černý, Business Development Manager, Flowmon Networks</p>'
			},
			{
				hours: '12:30 - 13:30',
				title: 'Working Buffet Lunch & Networking',
				desc: ''
			},
			{
				hours: '13:30 - 14:10',
				title: 'Workshop 3',
				desc: '<p><strong>Collaborating with digital consultancies to achieve aspired outcomes</strong></p>' +
					'<p>Over the past three years at LSHTM I have engaged with multiple differing digital consultancies and have had some great experiences, some significant learning points and have developed some key observations.  So, what are the offerings from collaborative partners, digital consultancies, who might be clearly specialists in one particular area i.e. data analytics, IoT, machine learning, integration, digital communication channels, culture and capabilities etc, but when you have needs across multiple touchpoints, do you end up engaging with multiple partners or trying to source one to cover all? What are the key criteria for selecting and working successfully with a digital consultancy for ongoing strategic change and benefit?</p>' +
					'<p><strong>3 key take-ways:</strong></p>' +
					'<ul><li>Analysis and observations from across the digital consultancy sector and HE engagements</li><li>First-hand examples and experiences of primary factors for consideration when choosing a collaborative partner</li><li>Indicators of where digital consultancies can offer direct and longer term value</li></ul>' +
					'<p>Hosted by:  Jon Faulkner, Chief Information Officer, LSHTM</p>'
			},
			{
				hours: '14:10 - 14:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '14:30 - 15:10',
				title: 'Workshop 4',
				desc: '<p><strong>International student recruitment - a sustainable model for universities of the future?</strong></p>' +
					'<p>It has been said many times that we need to reinvent how we market to, recruit and attract international students to our universities. Increasing international student populations has been key to the plans of forward-looking universities for years, but the process or recruitment is typically labour-intensive, costly and lacking in guaranteed results. And yet, curating a student body that represents diverse backgrounds and ways of thinking is the best possible route to adequately prepare young people for the challenges of our world, and to ensure that society is supported by enough people with the right skills and qualifications. We’ll be sharing insights sourced from working with secondary schools across 60 countries and discussing solutions for attracting, recruiting, and retaining a global student population, ready to meet the needs of the future workforce.</p>' +
					'<p>Hosted by:  Lucy Shonehill, Co-Founder, CEO, BridgeU</p>'
			},
			{
				hours: '15:10 - 15:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '15:30 - 16:10',
				title: 'Workshop 5',
				desc: '<p><strong>Don’t you know how busy I am? Maximising Learning Experience with Online Activities</strong></p>' +
					'<p>This workshop will provide an opportunity for delegates to share and learn best practice for a range on online learning activities. By using a specific case study, the workshop will identify some common challenges and hurdles to be addressed when designing, planning and conducting online study with associated learning activities for Masters level students.</p>' +
					'<p>From here, delegates will be able to contribute to discussions and a facilitated session in which they can learn from others as to current best practice in addressing and mitigating some of the challenges identified. It is hoped that a summary output can then be produced which can be shared to all participants that captures a summary of techniques and tips shared to maximise the experience of the online learning environment.</p>' +
					'<p><strong>3 key take-aways:</strong></p>' +
					'<ul><li>Online learning activities can be as valuable a student educational activity as classroom work.</li><li>Role of teacher / lecturer / facilitator can be very different when moderated through an online medium</li><li>Sharing of key tips for maximising online learning experience for students</li></ul>' +
					'<p>Hosted by:  Aurelie Soulier, Senior Learning Technologist, and Rob Black, Lecturer - Information Activities, Cranfield University</p>'
			},
			{
				hours: '16:10 - 16:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '16:30 - 17:10',
				title: 'Workshop 6',
				desc: '<p><strong>Amazon’s culture of innovation</strong></p>' +
					'<p>During this workshop, AWS Head of Public Sector Chris Hayman, will discuss how Amazon and AWS creates an environment which fosters a culture of innovation through latest technology. You will also find out what mechanisms can help you better optimise your cloud strategy with best in class students learning experience in mind.</p>' +
					'<p>Hosted by: Chris Hayman, Head of Public Sector, AWS</p>'
			},
			{
				hours: '17:10 - 17:30',
				title: 'Coffee & Networking',
				desc: ''
			}
		],
		evening: [
			{
				hours: '17:30 - 19:00',
				title: 'Cocktail Reception',
				desc: ''
			},
			{
				hours: '19:00 - 21:00',
				title: 'Gala Dinner',
				desc: ''
			}
		]
	}
}
