import React, { Component } from 'react';
import Banner from './Banner';

import catalogs from './images/register.jpg';

const website = 'CIO Education Congress 2018';

class Register extends Component {
	render() {
		return (
			<div>
				<Banner />
				<Form />
			</div>
		);
	}
}

export default Register;

class Form extends Component {
	constructor() {
		super();
		this.state = {
			name: '',
			company: '',
			position: '',
			email: '',
			mobile: '',
			landline: '',
			fail: false,
			failSubmit: false,
			success: false
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(e) {
		const states = ['name', 'company', 'position', 'email', 'mobile'];

		this.setState({
			fail: false,
			failSubmit: false,
			success: false
		});

		e.preventDefault();

		let check = states.every(elm => {
			return this.state[elm];
		});

		if (!check) {
			return this.setState({fail: true});
		}

		fetch('/email/register', {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				website: website,
				name: this.state.name,
				company: this.state.company,
				position: this.state.position,
				email: this.state.email,
				mobile: this.state.mobile,
				landline: this.state.landline
			})
		}).then((res) => {
			this.setState({success: true});
			[...states, 'landline'].forEach(elm => {this.setState({[elm]: ''})});
		}).catch((err) => {
			console.log(err);
			this.setState({failSubmit: true});
		});
	}

	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	render() {
		return (
			<div className='row' style={{paddingTop: 30}}>
				<div className='col-xs-12 col-sm-6'>
					<span className='color-grey'>Register to attend CIO Education Congress 2018</span>
					<br />
					<span>(Our team will contact you to confirm attendance)</span>
					<br />
					<br />
					<form className='form-horizontal'>
						<input type='hidden' name='website' value={website} />
						{[
							{
								title: 'Name',
								name: 'name',
								value: this.state.name,
								required: true
							},
							{
								title: 'Company',
								name: 'company',
								value: this.state.company,
								required: true
							},
							{
								title: 'Position',
								name: 'position',
								value: this.state.position,
								required: true
							},
							{
								title: 'Email',
								name: 'email',
								value: this.state.email,
								required: true
							},
							{
								title: 'Mobile Phone',
								name: 'mobile',
								value: this.state.mobile,
								required: true
							},
							{
								title: 'Office Phone',
								name: 'landline',
								value: this.state.landline,
								required: false
							}
						].map(elm => {
							return <FormInput key={elm.name} title={elm.title} name={elm.name} value={elm.value} required={elm.required} onChange={this.onChange} />
						})}
						<div style={{textAlign: 'center'}}>
							{this.state.fail ? (<div className='alert alert-danger' style={{marginBottom: 5}}>Please complete all required fields!</div>) : ''}
							{this.state.failSubmit ? (<div className='alert alert-danger' style={{marginBottom: 5}}>Something went wrong! Please call us to register you.</div>) : ''}
							{this.state.success ? (<div className='alert alert-success' style={{marginBottom: 5}}>Thank you for registering!</div>) : ''}
							<button type='button' className='btn bg-color-yellow' style={style.button} onClick={this.onSubmit}>Register</button>
						</div>
					</form>
				</div>
				<div className='col-xs-12 col-sm-6'>
					<img src={catalogs} style={{width: '100%'}} alt='' />
				</div>
			</div>
		);
	}
}

class FormInput extends Form {
	render() {
		let showRequired = this.props.required ? (<strong style={{color: 'red'}}>*</strong>) : '';

		return (
			<div className='form-group'>
				<div className='input-group' style={style.inputGroup}>
					<div className='input-group-addon bg-color-charcoal' style={style.inputGroupAddon}>{this.props.title} {showRequired}</div>
					<input
						className='form-control bg-color-grey'
						style={style.input}
						type='text'
						name={this.props.name}
						value={this.props.value}
						onChange={this.props.onChange}
					/>
				</div>
			</div>
		)
	}
}

const style = {
	inputGroup: {
		width: '100%',
		padding: '0px 10px'
	},
	inputGroupAddon: {
		width: '125px',
		borderColor: '#000',
		borderRadius: 0,
		color: '#fff',
		fontWeight: 'bold'
	},
	input: {
		color: '#fff',
		border: '0px',
		borderRadius: 0
	},
	button: {
		marginBottom: 30,
		fontWeight: 'bold',
		borderRadius: 0,
		border: 0
	}
}
