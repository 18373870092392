import React, { Component } from 'react';
import Banner from './Banner';

import managementTeam from './images/management-team.jpg';
import location from './images/location.svg';
import email from './images/mail.svg';
import phone from './images/phone.svg';
import linkedin from './images/linkedin.svg';

const data = [
	{
		icon: location,
		title: 'Office',
		text: 'Reeds Yard, Bradford Road, Melksham, Wiltshire, SN12 8LQ'
	},
	{
		icon: email,
		title: 'Email',
		text: 'info@paulclarkconsulting.co.uk'
	},
	{
		icon: phone,
		title: 'Phone',
		text: '01225 899061'
	},
	{
		icon: linkedin,
		title: 'LinkedIn',
		text: 'https://www.linkedin.com/company/11058413',
		link: 'https://www.linkedin.com/company/11058413'
	}
]

class Contact extends Component {
	render() {
		return (
			<div>
				<Banner />
				<div className="row" style={{paddingTop: 30}}>
					<div className='col-xs-12 col-sm-6' style={{paddingLeft: 25}}>
						{data.map(elm => {
							return <Row data={elm} key={elm.title} />
						})}
					</div>
					<div className='col-xs-12 col-sm-6'>
						<h3 style={{marginTop: 0}}>Management Team</h3>
						<img src={managementTeam} style={{width: '100%'}} alt='Management Team' />
						<span className='color-grey' style={{fontSize: '0.8em'}}>Left to Right:</span>
						<br />
						<span style={{fontSize: '0.9em', textAlign: 'justify'}}>Jason Hill - Finance & Operations Director, Brad King-Taylor - Sales Director, Paul Clark - CEO, Stefan Rascanu - IT Director, Katie Clark - Event Director</span>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;

class Row extends Component {
	render() {
		let text = this.props.data.link ? <a href={this.props.data.link} target='_blank' rel="noopener noreferrer" style={{overflowWrap: 'break-word'}}>{this.props.data.text}</a> : this.props.data.text;

		return (
				<div style={{marginBottom: 35}}>
					<div style={{float: 'left'}}>
						<img src={this.props.data.icon} style={{width: 50}} alt={this.props.data.title} />
					</div>
					<div style={{marginLeft: 85}}>
						<div style={{fontSize: '1.3em', marginBottom: 10}}>
							<strong>{this.props.data.title}</strong>
						</div>
						<div>{text}</div>
					</div>
				</div>
		)
	}
}
