import React, { Component } from 'react';

class ShortDescription extends Component {
	render() {
		return (
			<div className='row short-description'>
				<div style={{textAlign: 'center', paddingBottom: 20}}>
					<p className='color-dark-grey' style={{fontSize: '1.5em', margin: 0}}>
						<strong>CIO EDUCATION CONGRESS 2018</strong>
					</p>
				</div>
				<div className='col-xs-12 col-sm-6 col-md-6 col-lg-6' style={{textAlign: 'justify'}}>
					<p>The inaugural CIO Education Congress will bring together some of the UK’s leading CIOs and IT Directors from leading UK universities to showcase and discuss some of the key IT challenges across the education sector in the UK.</p>
					<p>The highly engaging program across a full day will piece together topics around cyber security, BI and analytics, student success technology, data management and much more. With the ever increasing need to demonstrate return on investment, sustain learning excellence and react to and manage change effectively, education sector CIOs need to continue to educate, thought-lead and keep up with the pace of change within their industry.</p>
				</div>
				<div className='col-xs-12 col-sm-6 col-md-6 col-lg-6' style={{textAlign: 'justify'}}>
					<p>The CIO Education Congress will provide a content-based platform of Keynotes, Panels, Workshops and Roundtables, all built into an exciting day agenda in the luxurious and breath-taking surroundings of the Bowood luxury hotel and spa, Calne, Wiltshire.</p>
					<p>Network with like-minded professionals to support your current strategic objectives. Attendance will give you the chance to learn how best to implement, build and buy the right solutions and technologies that are at the forefront of education IT improvement in the UK.</p>
					<p>We look forward to seeing you there!</p>
				</div>
			</div>
		);
	}
}

export default ShortDescription;
